import * as React from 'react';
import View from 'components/View';
import Button from 'components/Button';
import Paragraph from 'components/text/Paragraph';
import Link from 'components/Link';
import clsx from 'clsx';
import { useFooterItems, LEGAL_ITEMS } from 'utils/links';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { shouldRenderLoginAndRegister } from 'components/Header/Header.utils';
import dynamic from 'next/dynamic';
import FooterSection from './FooterLinks/FooterSection';
import { APP_ENTRY_ROUTE } from 'utils/routes';

const PoinzLogo = dynamic(() => import('components/Svg/PoinzMinmal'));

interface Props {
  className?: string;
}

const Footer: React.FC<Props> = ({ className }) => {
  const { t } = useTranslation();
  const router = useRouter();
  const renderLoginAndRegisterButtons = shouldRenderLoginAndRegister(router.pathname);
  const footerSections = useFooterItems();

  if (!renderLoginAndRegisterButtons) {
    return null;
  }

  const renderDivider = () => <div className="h-[1.5px] w-full bg-white"></div>;

  return (
    <footer className={clsx(className, 'bg-neutral-900 text-footer dark:border-t borde-t-white')}>
      {renderDivider()}
      <View className="px-6 py-10 md:flex md:gap-16">
        <Link
          aria-label="Poinz logo that will redirect you to the home page"
          href={APP_ENTRY_ROUTE}
          className="inline-block self-start sm:mt-10"
        >
          <PoinzLogo className="w-[100px] inline-block text-white" />
        </Link>
        <div className="columns-2 gap-16 sm:grid sm:grid-cols-5 sm:gap-2 w-full">
          {footerSections.map(({ gridPositionClass, section, label, items }) => (
            <FooterSection
              key={section}
              className={clsx(gridPositionClass, 'inline-block w-full max-w-[80%] mt-10')}
              label={label || ''}
              items={items || []}
              section={section}
            />
          ))}
        </div>
      </View>

      {renderDivider()}

      <View className="py-10 px-6">
        <div className="flex flex-col sm:flex-row-reverse sm:gap-x-3 gap-y-1 flex-wrap sm:justify-center">
          <div className="flex flex-col sm:flex-row sm:gap-x-3 gap-y-1 flex-wrap sm:justify-center">
            {LEGAL_ITEMS.map(item => (
              <div key={item.label} className="flex flex-col items-start gap-y-1">
                <Button
                  href={item.link}
                  size="sm"
                  variant="footer-link"
                  className="flex-[2] !py-2 leading-normal !px-0 underline"
                >
                  {t(item.label)}
                </Button>
              </div>
            ))}
          </div>
          <Paragraph className="flex items-center !text-neutral-300 mt-3 sm:mt-0">{`© ${new Date().getFullYear()} Poinz AG`}</Paragraph>
        </div>
      </View>
    </footer>
  );
};

export default Footer;
