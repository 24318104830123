import * as React from 'react';
import Image from 'next/image';
import Button from 'components/Button';
import { useTranslation } from 'next-i18next';
import clsx from 'clsx';
import analytics, { ANALYTIC_EVENTS, FOOTER_ANALYTIC_ITEM } from 'utils/firebase/analytics';
import { useRouter } from 'next/router';

interface Props {
  link?: string;
  src?: string;
  target?: string;
  label?: string;
  analyticEvent?: ANALYTIC_EVENTS;
  analyticKey?: FOOTER_ANALYTIC_ITEM;
  children?: React.ReactNode;
}

const FooterLink: React.FC<Props> = ({
  link,
  label,
  src,
  target,
  analyticEvent,
  analyticKey,
  children
}) => {
  const { t } = useTranslation();
  const { pathname } = useRouter();

  const handleLogEvent = () => {
    if (analyticEvent) {
      analytics.logEvent(analyticEvent, { origin: pathname });
      return;
    }

    if (analyticKey) {
      analytics.logEvent(ANALYTIC_EVENTS.FOOTER_ITEM, { name: analyticKey, origin: pathname });
    }
  };

  if (children) return children;

  return (
    label && (
      <li
        key={label}
        className={clsx('list-none', { ['py-2 md:py-[6px]   leading-normal']: !src })}
      >
        <Button
          href={link}
          target={target || '_blank'}
          size="sm"
          variant="footer-link"
          onClick={handleLogEvent}
        >
          {src ? <Image src={src} alt={t(label)} width={100} height={30} /> : t(label)}
        </Button>
      </li>
    )
  );
};

export default FooterLink;
