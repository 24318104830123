import * as React from 'react';
import { useRouter } from 'next/router';
import { routes } from './routes';
import { IWalletAssetType } from './wallet';
import { ANALYTIC_EVENTS, FOOTER_ANALYTIC_ITEM } from 'utils/firebase/analytics';
import { IProductTypes } from '@poinz/api';
import usePoinzCardsRouterLink from 'hooks/usePoinzCardsRouterLink';
import TrustpilotWidget, {
  TrustpilotWidgetTheme,
  TrustpilotWidgetType
} from 'components/Trustpilot/TrustpilotWidget';

export const LINKS_SECTIONS = {
  SHOP: 'SHOP',
  FOR_USERS: 'FOR_USERS',
  FOR_COMPANIES: 'FOR_COMPANIES',
  SUPPORT: 'SUPPORT',
  POINZ: 'POINZ',
  APP: 'APP',
  SOCIAL: 'SOCIAL',
  LANGUAGE_PICKER: 'LANGUAGE_PICKER',
  ABOUT_US: 'ABOUT_US'
};

export const WORDLINE_LINK = 'https://worldline.com/';

export const SUPPORT_MAILTO_LINK = 'mailto:support@poinz.ch';

export const DOWNLOAD_APP_DYNAMIC_LINK = 'https://poinz.page.link/poinz';

export const POINZ_INSTAGRAM_LINK = 'https://www.instagram.com/_poinz/';

export const POINZ_CAMP_LINK = 'https://admin.poinz.ch/login';

export const APP_STORE_LINK =
  'https://apps.apple.com/ch/app/poinz-stempelkarten-als-mobile/id547578059?l=en';
export const PLAY_STORE_LINK = 'https://play.google.com/store/apps/details?id=ch.poinz';

const getAboutUsFooterItems = (lang: string) => {
  return [
    {
      label: 'links.aboutUs.aboutPoinz',
      link: routes.aboutUs.index(),
      target: '_self',
      analyticKey: FOOTER_ANALYTIC_ITEM.ABOUT_US
    },
    {
      label: 'links.general.contact',
      link: routes.contactUs.index(),
      target: '_self',
      analyticKey: FOOTER_ANALYTIC_ITEM.CONTACT_US
    },
    {
      label: 'links.aboutUs.blog',
      link: routes.blog.index(),
      target: '_self',
      analyticKey: FOOTER_ANALYTIC_ITEM.CONTACT_US
    },
    {
      label: 'links.general.faq',
      link: `https://poinz.crisp.help/${lang}`,
      analyticKey: FOOTER_ANALYTIC_ITEM.FAQ
    }
    // {
    //   label: 'links.general.getSupport',
    //   link: '/'
    // }
  ];
};

const socialItems = [
  {
    label: 'links.general.tiktok',
    link: 'https://www.tiktok.com/@poinz_',
    analyticKey: FOOTER_ANALYTIC_ITEM.TIKTOK
  },
  {
    label: 'links.general.instagram',
    link: 'https://www.instagram.com/_poinz/',
    analyticKey: FOOTER_ANALYTIC_ITEM.INSTAGRAM
  },
  {
    label: 'links.general.facebook',
    link: 'https://www.facebook.com/poinzapp/',
    analyticKey: FOOTER_ANALYTIC_ITEM.FACEBOOK
  },
  {
    label: 'links.general.linkedin',
    link: 'https://ch.linkedin.com/company/poinz',
    analyticKey: FOOTER_ANALYTIC_ITEM.LINKEDIN
  },
  {
    label: 'links.general.youtube',
    link: 'https://www.youtube.com/@poinzcashback',
    analyticKey: FOOTER_ANALYTIC_ITEM.YOUTUBE
  }
];

const appItems = [
  {
    label: 'links.general.downloadIos',
    link: APP_STORE_LINK,
    src: '/assets/apple-store.svg',
    analyticEvent: ANALYTIC_EVENTS.DOWNLOAD_IOS_APP
  },
  {
    label: 'links.general.downloadAndroid',
    link: PLAY_STORE_LINK,
    src: '/assets/google-play-store.png',
    analyticEvent: ANALYTIC_EVENTS.DOWNLOAD_ANDROID_APP
  }
  // {
  //   children: (
  //     <TrustpilotWidget theme={TrustpilotWidgetTheme.LIGHT} type={TrustpilotWidgetType.SMALL} />
  //   )
  // }
];

const shopItems = [
  {
    label: 'links.shop.allOffers',
    link: routes.shop.index(),
    target: '_self',
    analyticKey: FOOTER_ANALYTIC_ITEM.SHOP
  },
  {
    label: 'links.shop.swissLoyaltyOnline',
    link: `${routes.shop.index()}?searchableTypes=${IProductTypes.AFFILIATE_ONLINE}`,
    target: '_self',
    analyticKey: FOOTER_ANALYTIC_ITEM.SHOP_ONLINE
  },
  {
    label: 'links.shop.swissLoyaltyLocal',
    link: `${routes.shop.index()}?searchableTypes=${IProductTypes.AFFILIATE_OFFLINE}`,
    target: '_self',
    analyticKey: FOOTER_ANALYTIC_ITEM.SHOP_IN_STORE
  },
  {
    label: 'links.shop.deals',
    link: `${routes.shop.index()}?searchableTypes=${IProductTypes.DEAL}`,
    target: '_self',
    analyticKey: FOOTER_ANALYTIC_ITEM.SHOP_DEALS
  },
  {
    label: 'links.shop.digitalVouchers',
    link: `${routes.shop.index()}?searchableTypes=${IProductTypes.GIFT_CARD}`,
    target: '_self',
    analyticKey: FOOTER_ANALYTIC_ITEM.SHOP_GIFT_CARDS
  }
];

const aboutUsItems = [
  { label: 'links.aboutUs.aboutPoinz', link: routes.aboutUs.index() },
  { label: 'links.aboutUs.contact', link: routes.contactUs.index() },
  { label: 'links.aboutUs.blog', link: routes.blog.index() }
];

// TODO: update links
const companyItems = [
  {
    label: 'links.companies.becomePartner',
    link: routes.becomePartner.index(),
    target: '_self',
    analyticKey: FOOTER_ANALYTIC_ITEM.BECOME_PARTNER
  },
  {
    label: 'links.companies.partnerCockpit',
    link: POINZ_CAMP_LINK,
    analyticKey: FOOTER_ANALYTIC_ITEM.PARTNER_LOGIN
  }
];

const getUserItems = (link: string) => [
  { label: 'links.users.creditCards', link },
  { label: 'links.users.browserExtension', link: routes.cashbackBrowserExtension.index() }
];

// TODO: update links
const getUserItemsFooter = (link: string) => [
  // { label: 'links.users.swissLoyaltyOnline', link: '/' },
  // { label: 'links.users.swissLoyaltyLocal', link: '/' },
  {
    label: 'links.users.swissLoyaltyCards',
    link,
    target: '_self',
    analyticKey: FOOTER_ANALYTIC_ITEM.POINZ_CARDS
  },
  {
    label: 'links.users.browserExtension',
    link: routes.cashbackBrowserExtension.index(),
    target: '_self',
    analyticKey: FOOTER_ANALYTIC_ITEM.CASHBACK_BROWSER_EXTENSION
  }
  // { label: 'links.users.swissLoyaltyExtension', link: '/' }
];

export const useFooterItems = () => {
  const router = useRouter();
  const poinzCardsLink = usePoinzCardsRouterLink();
  const userItemsFooter = React.useMemo(() => getUserItemsFooter(poinzCardsLink), [poinzCardsLink]);

  const footerItems = React.useMemo(
    () => [
      {
        section: LINKS_SECTIONS.SHOP,
        label: 'links.general.shop',
        items: shopItems,
        gridPositionClass: 'sm:row-[1/2] sm:col-[1/2]'
      },
      {
        section: LINKS_SECTIONS.FOR_USERS,
        label: 'links.general.users',
        items: userItemsFooter,
        gridPositionClass: 'sm:row-[1/2] sm:col-[2/3]'
      },
      {
        section: LINKS_SECTIONS.FOR_COMPANIES,
        label: 'links.general.companies',
        items: companyItems,
        gridPositionClass: 'sm:row-[1/2] sm:col-[3/4]'
      },
      {
        section: LINKS_SECTIONS.ABOUT_US,
        label: 'links.general.aboutUs',
        items: getAboutUsFooterItems(router.locale || 'de'),
        gridPositionClass: 'sm:row-[1/2] sm:col-[4/5]'
      },
      {
        section: LINKS_SECTIONS.SOCIAL,
        label: 'links.general.social',
        items: socialItems,
        gridPositionClass: 'sm:row-[1/2] sm:col-[5/6]'
      },
      {
        section: LINKS_SECTIONS.APP,
        label: 'links.general.app',
        items: appItems,
        gridPositionClass: 'sm:row-[2/3] sm:col-[1/2]'
      },
      {
        section: LINKS_SECTIONS.LANGUAGE_PICKER,
        gridPositionClass: 'sm:row-[2/3] sm:col-[2/3]'
      }
    ],
    [router.locale, userItemsFooter]
  );
  return footerItems;
};

export const useHeaderItems = () => {
  const poinzCardsLink = usePoinzCardsRouterLink();

  return [
    // can be {section, label, items} when has more links
    // or single link {label, link}
    {
      section: LINKS_SECTIONS.SHOP,
      label: 'links.general.shop',
      link: routes.shop.index(),
      items: shopItems,
      analyticEvent: ANALYTIC_EVENTS.SHOP
    },
    {
      section: LINKS_SECTIONS.FOR_USERS,
      label: 'links.general.users',
      link: poinzCardsLink,
      items: getUserItems(poinzCardsLink)
    },
    {
      section: LINKS_SECTIONS.FOR_COMPANIES,
      label: 'links.general.companies',
      link: routes.becomePartner.index(),
      items: companyItems,
      analyticEvent: ANALYTIC_EVENTS.FOR_COMPANIES
    },
    {
      section: LINKS_SECTIONS.ABOUT_US,
      label: 'links.general.aboutUs',
      link: routes.aboutUs.index(),
      items: aboutUsItems,
      analyticEvent: ANALYTIC_EVENTS.ABOUT_US
    }
  ];
};

export const LEGAL_ITEMS = [
  {
    label: 'links.general.termsOfUse',
    link: `/legal/terms-of-use`
  },
  {
    label: 'links.general.imprint',
    link: `/imprint`
  },
  {
    label: 'links.general.dataPrivacy',
    link: `/legal/privacy-notice`
  }
];

// TODO: update links
export const USER_MENU_LINKS = [
  {
    label: 'links.general.overview',
    link: routes.account.overview.index(),
    analyticEvent: ANALYTIC_EVENTS.ACCOUNT_OVERVIEW
  },
  // {
  //   label: 'links.general.balanceAndActivity',
  //   link: `/`
  // },
  // {
  //   label: 'links.general.addPaymentMethod',
  //   link: `/`
  // },
  {
    label: 'links.general.wallet',
    link: routes.account.wallet.assetType(IWalletAssetType.DEAL).index(),
    analyticEvent: ANALYTIC_EVENTS.ACCOUNT_WALLET
  },
  {
    label: 'links.general.cashback',
    link: routes.account.cashback.balanceAndActivity(),
    analyticEvent: ANALYTIC_EVENTS.ACCOUNT_CASHBACK
  },
  {
    label: 'links.general.myReceipts',
    link: routes.account.receipts.index(),
    analyticEvent: ANALYTIC_EVENTS.ACCOUNT_RECEIPTS
  }
  // {
  //   label: 'links.general.howItWorks',
  //   link: `/`
  // }
];

export enum EScreenPath {
  WALLET_CARD_DETAILS_TABS = 'WALLET_CARD_DETAILS_TABS'
}

export const SCREEN_PATH = {
  [EScreenPath.WALLET_CARD_DETAILS_TABS]: 'poinz/app/tabs/wallet/card-details/tabs'
};

export const getAppScreenPath = (screenPath: EScreenPath) => {
  const path = SCREEN_PATH[screenPath];
  return `${process.env.NEXT_PUBLIC_APP_SCHEME}://${path}`;
};

export const EXTENSION_URL = {
  CHROME:
    'https://chromewebstore.google.com/detail/poinz-cashback-helper/dpgdgcmimnjdaglejhehkebkbapajcfo',
  SAFARI: 'https://apps.apple.com/ch/app/poinz-cashback-helper/id6450549820'
};
