import { useFeaturedList } from 'api/reactQuery/featuredItems';
import { useAppContext } from 'contexts/app';
import { useRouter } from 'next/router';
import { Language } from 'types/lang';
import { FEATURED_SECTION_LIST } from 'utils/contentful/featuredList';
import { routes } from 'utils/routes';

export const useIsTransparentHeaderPage = pathname => {
  const { data } = useFeaturedList(FEATURED_SECTION_LIST.SHOP_HERO);
  const TRANSPARENT_PATHS = [
    routes.aboutUs.index(),
    routes.contactUs.index(),
    routes.becomePartner.index(),
    routes.cashbackBrowserExtension.index()
  ];
  // if there is a hero section on shop page make header transparent
  if (data?.length) {
    TRANSPARENT_PATHS.push(routes.shop.index());
  }
  return !!TRANSPARENT_PATHS.find(path => path === pathname);
};
export const getLanguageOptions = () => {
  return (Object.keys(Language) as Array<keyof typeof Language>).map(LANG => {
    const lang = LANG.toLowerCase();
    return {
      label: `general.${lang}`,
      value: lang,
      image: {
        src: `/assets/languages/${lang}.svg`,
        alt: `general.${lang}`
      }
    };
  });
};

export const shouldRenderLoginAndRegister = (pathname: string): boolean => {
  const BLACKLISTED_PATHS = ['/login', '/register'];

  return !BLACKLISTED_PATHS.find(path => path === pathname) && !pathname.includes('/auth');
};

export const useIsHeaderTransparent = (): boolean => {
  const { pathname } = useRouter();

  const { isHeaderTransparent } = useAppContext(state => ({
    isHeaderTransparent: state.isHeaderTransparent
  }));

  const isOnPageWithTransparentHeader = useIsTransparentHeaderPage(pathname);

  return isOnPageWithTransparentHeader && isHeaderTransparent;
};

interface GetShowGlobalSearchParams {
  pathname: string;
}

export const getShowGlobalSearch = ({ pathname }: GetShowGlobalSearchParams): boolean => {
  const BLACKLISTED_PATHS = [
    routes.checkout.overview(),
    routes.checkout.purchase(),
    routes.auth.confirmAccount(),
    routes.auth.forgotPassword(),
    routes.auth.login(),
    routes.auth.register(),
    routes.auth.resetPassword(),
    routes.home.index(),
    routes.shop.index()
  ];
  const blacklistedPage = BLACKLISTED_PATHS.find(path => path === pathname);
  return !blacklistedPage;
};
