import { fetchGraphQL } from './api';

export enum FEATURED_SECTION_LIST {
  SHOP_INLINE = 'SHOP_INLINE',
  SHOP_HERO = 'SHOP_HERO',
  GLOBAL_SEARCH = 'GLOBAL_SEARCH',
  HOME_INLINE = 'HOME_INLINE'
}

export enum FEATURED_LIST_ITEM_TYPE {
  DEAL = 'DEAL',
  GIFT_CARD = 'GIFT_CARD',
  AFFILIATE = 'AFFILIATE'
}

export interface PictureCollecton {
  url: string;
  title: string;
}

export interface FeaturedListItem {
  entityId: string;
  entityType: FEATURED_LIST_ITEM_TYPE;
  heroLogo: PictureCollecton;
  heroImage: PictureCollecton;
  heroTitle: string;
  heroSubtitle: string;
}

export async function getFeaturedListByType(
  listType: FEATURED_SECTION_LIST,
  locale
): Promise<Array<FeaturedListItem>> {
  const query = `query {
    featuredItemListCollection(limit: 10, preview: false, where:{ type: "${listType}"  }, locale: "${locale}") {
      items {
        itemsCollection {
          items {
            heroLogo {
              title,
              url
            }
            heroImage {
              title,
              url
            }
            entityId,
            entityType,
            heroTitle,
            heroSubtitle
          }
        }
      }
    }
  }`;

  const results = await fetchGraphQL(query, false);

  const items = results?.data?.featuredItemListCollection?.items?.[0]?.itemsCollection?.items;

  if (!items?.length) return [];

  return items;
}
