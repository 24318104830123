import { FEATURED_SECTION_LIST } from 'utils/contentful/featuredList';
import { FETCH_KEYS } from '.';
import { useQuery } from '@tanstack/react-query';

export const getFeaturedListKeyByType = (type: FEATURED_SECTION_LIST) => {
  return [FETCH_KEYS.FEATURED_LIST, type];
};

export const useFeaturedList = (type: FEATURED_SECTION_LIST) =>
  useQuery<Array<any>>(getFeaturedListKeyByType(type));
