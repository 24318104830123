import * as React from 'react';
import Paragraph from 'components/text/Paragraph';
import { useTranslation } from 'next-i18next';
import FooterLink from './FooterLink';
import { LINKS_SECTIONS } from 'utils/links';
import clsx from 'clsx';
import dynamic from 'next/dynamic';
import { ANALYTIC_EVENTS, FOOTER_ANALYTIC_ITEM } from 'utils/firebase/analytics';

const LanguageSwitcher = dynamic(() => import('components/LanguageSwitcher'));

interface Props {
  className?: string;
  section: string;
  label: string;
  items: {
    link?: string;
    src?: string;
    label?: string;
    analyticEvent?: ANALYTIC_EVENTS;
    analyticKey?: FOOTER_ANALYTIC_ITEM;
    children?: React.ReactNode;
  }[];
}

const FooterSection: React.FC<Props> = ({ className, section, label, items }) => {
  const { t } = useTranslation();

  return (
    <div className={className}>
      {section === LINKS_SECTIONS.LANGUAGE_PICKER ? (
        <LanguageSwitcher
          btnClass="bg-transparent hover:bg-transparent focus:bg-transparent !p-0 text-white hover:text-white/80"
          longLabel
        />
      ) : (
        <>
          <Paragraph className="mb-4 font-semibold text-white">{t(label)}</Paragraph>
          <ul
            className={clsx('!font-normal', {
              ['flex flex-col gap-3']: section === LINKS_SECTIONS.APP
            })}
          >
            {items.map(item => (
              <FooterLink key={item.label} {...item} />
            ))}
          </ul>
        </>
      )}
    </div>
  );
};

export default FooterSection;
