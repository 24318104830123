import { IEgiftBarcodeType, WALLET_ASSET_TYPE } from '@poinz/api';
import { useRouter } from 'next/router';
import { routes } from './routes';
import { formatQueryParams } from './affiliate';

export enum IWalletAssetType {
  'DEAL' = 'deal',
  'DIGITAL_VOUCHER' = 'digital-voucher',
  'COUPON' = 'coupon'
}

export const validAssetType = (urlAssetType: IWalletAssetType) => {
  // TODO add support for other types as we implement them
  if (urlAssetType === IWalletAssetType.DEAL || urlAssetType === IWalletAssetType.DIGITAL_VOUCHER) {
    return true;
  }
  return false;
};

export const mapUrlToApiWalletAssetType = (urlAssetType: IWalletAssetType): WALLET_ASSET_TYPE => {
  switch (urlAssetType) {
    case IWalletAssetType.DEAL:
      return WALLET_ASSET_TYPE.DEAL;
    case IWalletAssetType.COUPON:
      return WALLET_ASSET_TYPE.COUPON;
    case IWalletAssetType.DIGITAL_VOUCHER:
      return WALLET_ASSET_TYPE.CARD;
  }
};

export const useGetSelectedWalletTab = () => {
  const { asPath } = useRouter();
  switch (asPath) {
    case routes.account.wallet.assetType(IWalletAssetType.DEAL).index():
      return IWalletAssetType.DEAL;
    case routes.account.wallet.assetType(IWalletAssetType.DIGITAL_VOUCHER).index():
      return IWalletAssetType.DIGITAL_VOUCHER;
    case routes.account.wallet.assetType(IWalletAssetType.COUPON).index():
      return IWalletAssetType.COUPON;
  }
};

export const eGiftImageBarcodeTypes = [
  IEgiftBarcodeType.AZTEC,
  IEgiftBarcodeType.PDF417,
  IEgiftBarcodeType.DATAMATRIX
];

export const DEFAULT_BARCODE_IMAGE_SIZES = {
  width: 300,
  height: 150
};

export const getBarcodeImageUrl = (
  params: {
    height: number;
    width: number;
    token: string;
    type: IEgiftBarcodeType;
  },
  apiRoot: string
) => `${apiRoot}/${'barcodes'}/image?${formatQueryParams(params)}`;
